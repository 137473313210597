/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@angular/cdk/overlay-prebuilt.css';
@import 'ngx-toastr/toastr';
@import 'mixins';
@import 'techscan-theme.scss';

:root {
  --color-01: #146cfc;
  --color-02: #d8dae2;
  --color-03: #ffffff;
  --color-04: #cadefd;
  --color-05: #d8d8d83d;
  --color-06: #757575;
  --color-07: #bebebe;
  --color-08: #3a3d4f;
  --color-09: #0c59d4;
  --color-10: #ecf2ff;
  --color-11: #baceed;
  --color-12: #3a3b450d;
  --color-13: #e3e6f0;
  --color-14: #24252f;
  --color-15: #f2f6fd;
  --color-16: #3d64d3;
  font-size: 16px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

a {
  outline: none;
}

.c-app-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &_label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1.25rem;
  }

  &_descricao {
    font-size: 1rem;
    min-width: 16rem;
    width: 50%;
    margin-bottom: 1rem;

    @include mq(lg) {
      width: 100%;
    }
  }

  &_field {
    min-width: 16rem;
    width: 50%;

    @include mq(lg) {
      width: 100%;
    }
  }
}

.c-app-tabela {
  &_cabecalho {
    font-size: 1rem !important;
    color: var(--color-03) !important;
    background: var(--color-16) !important;
  }
}

.c-collapse-menu-lateral {
  &_container {
    background: var(--color-01) !important;
  }
  &_cabecalho {
    transition: 0.3s !important;
    border-bottom: 0.07rem solid var(--color-05) !important;
  }
  &_titulo {
    font-weight: 600 !important;
    font-size: 1rem !important;
    color: var(--color-03) !important;
  }
  &_conteudo {
    display: flex !important;
    flex-direction: column !important;
    background: var(--color-03) !important;
    border-radius: 0.4rem !important;
    padding: 1rem !important;
    margin-top: 1rem !important;
  }
  &_link {
    border-bottom: 0.07rem solid var(--color-04) !important;
    color: var(--color-08) !important;
    text-decoration: none !important;
    font-weight: 500 !important;
    padding: 0.5rem 0 !important;
    transition: 0.3s !important;
    &:last-child {
      border-bottom: none !important;
    }
    &:hover {
      background: var(--color-02) !important;
    }
  }
}

.is-aberto {
  background: var(--color-09) !important;
}

.is-ativo {
  background: var(--color-02) !important;
}

::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-thumb {
  background: var(--color-06);
  border-radius: 0.4rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-07);
}

.mat-drawer-inner-container {
  overflow: hidden !important;
  -webkit-overflow-scrolling: unset !important;
}

.u-font-size-1rem {
  font-size: 1rem !important;
}

.u-font-weight-600 {
  font-weight: 600 !important;
}

.u-font-weight-700 {
  font-weight: 700 !important;
}

.u-margin-bottom-1_25rem {
  margin-bottom: 1.25rem !important;
}

.u-margin-right-0_75rem {
  margin-right: 0.75rem !important;
}

.u-resize-none {
  resize: none !important;
}

.u-width-100p {
  width: 100% !important;
}

.u-text-warn {
  color: mat.get-theme-color($techscan-theme, warn) !important;
}

.u-align-self-center {
  align-self: center !important;
}

.is-titulo {
  margin-bottom: 1.25rem !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}

// Definição do mixin
@mixin mq($breakpoint) {
  @if $breakpoint == lg {
    @media (max-width: 768px) {
      @content;
    } // Ajuste para o tamanho que você deseja para mobile
  }
  // Se precisar de outros tamanhos de breakpoints, pode adicionar mais condições aqui
}

mat-icon {
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

app-editar-cliente {
  width: 100% !important;
}

app-cadastrar-cliente {
  width: 100% !important;
}

.cadastrar-cliente {
  max-width: none !important;
}

app-cadastrar-retrabalho {
  width: 100% !important;
}

app-input-cliente {
  width: 100% !important;
}
